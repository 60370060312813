import { ref, watch, computed } from '@vue/composition-api'
// import store from '@/store'
// import { title } from '@core/utils/filter'

// Notification
// import { useToast } from 'vue-toastification/composition'
// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCategoriasList() {
  // Use toast
  // const toast = useToast()

  const refCategoriaListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'nombre_cat', label: 'Nombre Categoria', sortable: true },
    { key: 'descripcion_cat', label: 'Descripcion', sortable: false },
    {
      key: 'createdAt',
      label: 'Creado',
      sortable: true,
    },
    { key: 'estado', label: 'Estado', sortable: true },
    { key: 'acciones', label: 'Acción' },
  ]
  const perPage = ref(10)
  const totalUsers = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const categoriaFilter = ref(null)
  const carreraFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refCategoriaListTable.value ? refCategoriaListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalUsers.value,
    }
  })

  const refetchData = () => {
    console.log(refCategoriaListTable)
    refCategoriaListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, categoriaFilter, carreraFilter, statusFilter], () => {
    console.log(statusFilter.value)
    refetchData()
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  /*
  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }
  */

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  return {
    tableColumns,
    perPage,
    currentPage,
    totalUsers,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refCategoriaListTable,

    // resolveUserRoleVariant,
    // resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    categoriaFilter,
    carreraFilter,
    statusFilter,
  }
}
